import React, { lazy } from 'react';
import companyAssignRoutes from './CompanyAssignment/CompanyAssignRoutes';
import bankAccountRoutes from './RoutesModule/BankAccountRoutes';
import docNumRoutes from './RoutesModule/DocNumRoutes';
import glMappingRoutes from './RoutesModule/GLMappingRoutes';
import watermarkSetupRoutes from './RoutesModule/WatermarkSetupRoutes';

const CompanyListing = lazy(() =>
  import('./CompanyListing').then(module => ({
    default: module.CompanyListing,
  })),
);
const CompanyForm = lazy(() =>
  import('./CompanyForm').then(module => ({
    default: module.CompanyForm,
  })),
);
const CompanySubmenu = lazy(() =>
  import('./CompanySubmenu').then(module => ({
    default: module.CompanySubmenu,
  })),
);
const CompanyProfile = lazy(() =>
  import('./CompanyProfile').then(module => ({
    default: module.CompanyProfile,
  })),
);

const companyRoutes = [
  {
    props: { exact: true, path: '/company-setup' },
    component: <CompanyListing />,
  },
  {
    props: { exact: true, path: '/company-form/add' },
    component: <CompanyForm mode="add" />,
  },
  {
    props: { exact: true, path: '/company-form/edit/:CompanyID' },
    component: <CompanyForm mode="edit" />,
  },
  {
    props: { exact: true, path: '/company-setup/submenu/:CompanyID' },
    component: <CompanySubmenu />,
  },
  {
    props: { exact: true, path: '/company-setup/submenu/:CompanyID/details' },
    component: <CompanyProfile />,
  },
  ...bankAccountRoutes,
  ...docNumRoutes,
  ...watermarkSetupRoutes,
  ...companyAssignRoutes,
  ...glMappingRoutes,
];

export default companyRoutes;
